import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { message, notification } from "antd";

import { IUseInvestorData } from "./IUseInvestorData";
import { ContractData } from "../Models/Contracts";
import { InvestorDTO } from "../Models/InvestorAPI";
import { Utils } from "../Utils";

export const useInvestorData = ({
	translations,
	contractAPI,
	investorsAPI,
	institutionData,
	templatesAPI,
	selectedPersonType,
	setSelectedPersonType,
	unlockNextStep,
	userIdInstitution,
	isCreand
}: IUseInvestorData) => {
	const navigate = useNavigate();
	const [searchParams, _] = useSearchParams();

	const [loading, setLoading] = useState<boolean>(false);
	const [noContent, setNoContent] = useState<boolean>(false);
	const [contractData, setContractData] = useState<ContractData>({
		numSignatories: 1,
		idPersonType: selectedPersonType,
		commitment: 0,
		idInstitution: 0,
		vehicleId: 0,
		id: 0,
		idLanguage: 1,
		idContractState: 1,
		bp: undefined,
		container: undefined
	});
	const [error, setError] = useState<boolean>(false);
	const [templateList, setTemplateList] = useState<any>([]);
	const [investorsData, setInvestorsData] = useState<InvestorDTO[]>([
		{
			name: undefined,
			lastName: undefined,
			emailContact: undefined,
			dni: undefined,
			nif: undefined,
			passport: undefined,
			residenceCard: undefined,
			documentType: undefined,
			phoneNumber: undefined,
			phonePrefix: undefined,
			american: false,
			organism: false,
			contributor: false,
		}
	]);

	const [errors, setErrors] = useState<any>({
		noVehicle: false,
		noCommitment: false,
		noTemplate: false,
		noInvestorsData: false,
		noValidEmail: false
	});

	useEffect(() => {
		if (searchParams.has('ContractId')) {
			configureInitialData(Number(searchParams.get('ContractId')));
		}
		getAllTemplates();
	}, []);

	useEffect(() => {
		templateExists();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [contractData.idLanguage, 
		contractData.idPersonType, 
		contractData.vehicleId
	]);

	const configureInitialData = async(ContractId: number) => {
		setLoading(true);
		await contractAPI.getContract(ContractId).then(async(res) => {
			if (res.success) {
				await investorsAPI.getInvestors(ContractId).then((resInvestors) => {
					if(resInvestors.success){
						let investors = resInvestors.data?.map((x: any) => {
							if(x.dni) x.documentType = 'dni';
							if(x.nif) x.documentType = 'nif';
							if(x.passport) x.documentType = 'passport';
							if(x.residenceCard) x.documentType = 'residenceCard';
							return x;
						});
						let firstInvestor = resInvestors.data?.[0];
						let otherData;
						if(firstInvestor){
							otherData = {
								vehicleId: Number(firstInvestor.idVehicle),
								commitment: firstInvestor.commitment,
								bp: firstInvestor.bp, 
								container: firstInvestor.container
							}
						}
						setSelectedPersonType(res.data.idPersonType)
						setContractData({...res.data, ...otherData});
						setInvestorsData(investors);
						setLoading(false);
					}
				})
				//setContractInstitution(res.idInstitution);
			} else {
				setContractData({ ...contractData, idInstitution: institutionData!.idInstitution});
				setNoContent(true);
			}
		})
		.catch(err => {
			console.log(err);
		});
		setLoading(false);
	};

	const postContract = async(data: any) => {
		await contractAPI.postContract(data).then(async (res) => {
			if(res.success && res.data){
				await postInvestors(investorsData, res.data);
			}else{
				message.error(translations.messageError);
			}
			setLoading(false);
		})
		.catch(err => {
			setLoading(false);
			message.error(translations.messageError);
		})
	}

	const updateContract = async(data: any) => {
		let updateData = {...data, id: contractData.id};
		await contractAPI.updateContract(updateData).then(async (res) => {
			if(res.success){
				let newInvestors = investorsData.filter((x: any) => !x.id);
				let investorsToUpdate = investorsData.filter((x: any) => x.id && x.id !== 0);
				if(newInvestors.length > 0){
					await postInvestors(newInvestors, contractData.id);
				}
				if(investorsToUpdate.length > 0){
					await updateInvestors(investorsToUpdate);
				}
			}else{
				message.error(translations.messageError);
			}
		})
		.catch(err => {
			message.error(translations.messageError)}
		)
		setLoading(false);
	};

	const postInvestors = async(investors: any, contractId: number) => {
		let data = investors.map((x: any, index: number) => {
			if(index === 0){
				return {
					...x,
					IdContract: contractId,
					Commitment: contractData.commitment,
					IdVehicle: contractData.vehicleId,
					IdInstitutionRef: userIdInstitution,
					BP: contractData.bp,
					Container: contractData.container	
				}
			}
			return {
				...x,
				IdContract: contractId,
				Commitment: contractData.commitment,
				IdVehicle: contractData.vehicleId,
				IdInstitutionRef: userIdInstitution,
			}
		});
		await investorsAPI.postInvestors(data).then(async (resInvestors) => {
			if(resInvestors.success){
				message.success(translations.datosGuardados);
				unlockNextStep();
				navigate(`?ContractId=${contractId}`);
				setLoading(false);
			}else{
				message.error(translations.messageError);
			}
		})
	};


	const updateInvestors = async(investors: any) => {
		let data = investors.map((x: any) => {
			return {
				...x,
				commitment: contractData.commitment,
			}
		});
		await investorsAPI.updateInvestors(data).then(res => {
			if(res.success){
				message.success(translations.datosGuardados);
				setLoading(false);
			}
		})
		.catch(err => {
			setLoading(false);
			message.error(translations.messageError);
		})
	}

	const getAllTemplates = async() => {
		await templatesAPI.getTemplateList().then((res) => {
			if(res.success){
				setTemplateList(res.data);
			}
		})
		.catch(err => {
			message.error('Error with templates');
		})
	}

	const saveData = async(numSig?: number) => {
		setLoading(true);
		if (validateData()) {
			let data = {
				numSignatories: parseInt( contractData.numSignatories.toString()),
				idPersonType: contractData.idPersonType,
				idVehicle: contractData.vehicleId,
				idLanguage: contractData.idLanguage,
				bp: contractData.bp,
				container: contractData.container,
			}
			let updateData = {...contractData, numSignatories: numSig ? numSig : contractData.numSignatories};
			contractData.id ? await updateContract(updateData) : await postContract(data);
			setErrors((prevState: any) => ({
				...prevState,
				noVehicle: false,
				noCommitment: false,
				noInvestorsData: false,
				noValidEmail: false,
				noTemplate: false,
				bp: false,
				container: false
			}));
		}
		else {
			setLoading(false);
		}
	};

	const validateData = () => {
		let isOK = true;
		if(isCreand && (!contractData.bp || !contractData.container)){
			isOK = false
			setErrors((prevState: any) => ({
				...prevState,
				bp: contractData.bp ? false : true,
				container: contractData.container ? false : true
			}));
		}
		if (!contractData.vehicleId) {
			setErrors((prevState: any) => ({
				...prevState,
				noVehicle: true
			}));
			isOK = false;
		}
		if (contractData.commitment <= 0) {
			setErrors((prevState: any) => ({
				...prevState,
				noCommitment: true
			}));
			isOK = false;
		}
		investorsData.forEach((investor: InvestorDTO) => {
			if (!investor.name 
				|| !investor.lastName 
				|| (!investor.dni && !investor.nif && !investor.passport && !investor.residenceCard) 
				|| !investor.phoneNumber 
				|| !investor.phonePrefix 
				|| !investor.documentType 
				|| !investor.birthDate) {
				setErrors((prevState: any) => ({
					...prevState,
					noInvestorsData: true
				}));
				isOK = false;
			}
		});
		investorsData.forEach((investor: InvestorDTO) => {
			if (!investor.emailContact || !Utils.validateEmail(investor.emailContact)) {
				setErrors((prevState: any) => ({
					...prevState,
					noValidEmail: true
				}));
				isOK = false;
			}
		});
		return isOK;
	};

	const getVehicleName = () => {
		const vehicle = institutionData?.institutionValues.find((x:any)=> x.vehicleId === contractData.vehicleId);
		return vehicle ? vehicle.vehicleLegalName : ''
	};

	const isAllowedAmount = async(amount: number)=> {
		if (amount) {
			await contractAPI.checkAllowedAmount(institutionData!.institutionValues[0].vehicleId, institutionData!.idInstitution, amount)
			.then((res: any) => {
				setError(!res.data);
				if (res.data === false) {
					notification.error({
						message: `${getVehicleName()} ${translations.limiteExcedidoTitulo}`,
						description: translations.limiteExcedido,
						duration: 10
					});
				}
				
			}).catch(err => {
				message.error(translations.messageError)
			});
		} else {
			setError(false);
		}
	};

	const onChangeVehicle = (e: any) => {
		setContractData(prevState => ({
			...prevState,
			vehicleId: e,
		}));
		setErrors((prevState: any) => ({
			...prevState,
			noVehicle: false
		}));
	};

	const onChangeCreandData = (e: any, type: string) => {
		setContractData(prevState => ({
			...prevState,
			[type]: e,
		}));
	}

	const onDeleteInvestor = async(index: number) => {
		let investors = [...investorsData];
		let investor = investors[index];
		let numSignatories = contractData.numSignatories - 1;
		if(investor.id){
			setLoading(true)
			await investorsAPI.deleteInvestor(investor.id).then((res: any) => {
				setLoading(false)
			})
			.catch((err: any) => {
				message.error(translations.messageError);		
				setLoading(false)

			})
			if(contractData.id > 0){
				await saveData(numSignatories);
				await configureInitialData(Number(searchParams.get('ContractId')));
			}
		}
		investors.splice(index, 1);
		setInvestorsData(investors);
		setContractData(prevState => ({
			...prevState,
			numSignatories: numSignatories
		}));
		
	}
	
	const onChangePersonType = (e: any) => {
		setContractData(prevState => ({
			...prevState,
			idPersonType: e
		}));
		setSelectedPersonType(e);
	};

	const onChangeLanguage = (e: any) => {
		setContractData(prevState => ({
			...prevState,
			idLanguage: e
		}));
	}

	const onChangeCommitment = (e: any) => {
		setContractData(prevState => ({
			...prevState,
			commitment: e
		}));
		setErrors((prevState: any) => ({
			...prevState,
			noCommitment: false
		}));
	};

	const onChangeInvestorsData = (index: number, value: any, fieldName: string) => {
		if(fieldName === 'documentType'){
			const updatedElements = investorsData.map((element: any, i: number) =>
			  i === index ? { ...element, [fieldName]: value, dni: '', nif: '', residenceCard: '', } : element
			);
			setInvestorsData(updatedElements);
		}
		else{
			const updatedElements = investorsData.map((element: any, i: number) =>
			i === index ? { ...element, [fieldName]: value } : element
		  );
		  setInvestorsData(updatedElements);
		}
		if(fieldName === 'emailContact'){
			setErrors((prevState: any) => ({
				...prevState,
				noValidEmail: false
			}));
		}
	  };
	
	  const addInvestor = () => {
		setInvestorsData((prevInvestorsData: any) => [
		  ...prevInvestorsData,
		  {
			name: undefined,
			lastName: undefined,
			emailContact: undefined,
			dni: undefined,
			nif: undefined,
			documentType: undefined,
			phoneNumber: undefined,
			phonePrefix: undefined
		}]);	
		setContractData(prevState => ({
			...prevState,
			numSignatories: contractData.numSignatories + 1
		}));
	  };

	  const templateExists = () => {
		const errorsCopy = {...errors};
		let exists = false;

		const { idPersonType, vehicleId, idLanguage } = contractData;
		if(templateList.length === 0) return false;

		if(!idPersonType || !vehicleId || !idLanguage) return false;
		const template = templateList.find((x: any) =>
			 x.idPersonType === idPersonType
			  && x.idVehicle === vehicleId 
			  && x.idLanguage === idLanguage
		);
		console.log(template?.name);
		
		if(!template){
			errorsCopy.noTemplate = true;
			exists = false;
		}else{
			errorsCopy.noTemplate = false;
			exists = true;
		}
		setErrors(errorsCopy);
		return exists;
	}
		  
	return {
		loading,
		noContent,
		errors,
		contractData,
		error,
		onChangeVehicle,
		onChangePersonType,
		isAllowedAmount,
		onChangeCommitment,
		saveData,
		onChangeLanguage,
		onChangeInvestorsData,
		investorsData,
		addInvestor,
		onDeleteInvestor,
		onChangeCreandData
	};
};